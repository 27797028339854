<template>
  <div>
    <b-row>
      <div class="d-flex justify-content-center mb-3 col-12 text-center">
        <h2
          data-wsa=""
          data-wsa-delay="reset"
          class="wsa-slideInLeft"
          style="animation-delay: 0s"
        >
          <strong data-svgtext="gold" class="gold"
            ><span class="outline"
              ><font style="vertical-align: inherit"
                ><font style="vertical-align: inherit">ROADMAP</font></font
              ></span
            ></strong
          >
        </h2>
      </div>
    </b-row>

    <b-row class="pb-3">
      <b-col md="6" class="card-class">
        <div class="section_header lite">
          <div class="d-flex justify-content-center col-12 text-center">
            <h3>Phase 01</h3>
          </div>
        </div>
        <ul class="section_footer">
          <li class="pt-1">
            <!-- <img
              src="https://jokercoin.live/_gfx/li_check_on.png"
              alt=""
              class="right-img"
            /> -->
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />
            <span>RugScan Telegram bot launch. (October)</span>
          </li>
          <li>
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />
            <span>RugScan premium scan feature for the holders. (October)</span>
          </li>
          <li>
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />
            <span>RugScan user interaction for scanned results. (October)</span>
          </li>
          <li>
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />
            <span>Functional test improvement.</span>
          </li>
          <li>
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />
            <span>Accuracy improvement.</span>
          </li>
          <li>
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />

            <span>Automatic contract checking.</span>
          </li>
          <li>
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />
            <span>Token launches</span>
          </li>
        </ul>
      </b-col>
      <b-col md="6" class="card-class">
        <div class="section_header lite">
          <div class="d-flex justify-content-center col-12 text-center">
            <h3>Phase 02</h3>
          </div>
        </div>
        <ul class="section_footer">
          <li class="pt-1">
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />
            <span>ETH contract scanner.</span>
          </li>
          <li>
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />
            <span>KCC contract scanner.</span>
          </li>
          <li>
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />
            <span>MATIC contract scanner.</span>
          </li>
          <li>
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />
            <span>POLYGON contract scanner.</span>
          </li>
          <li>
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />
            <span>SOL contract scanner.</span>
          </li>
          <li>
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />
            <span>TRX contract scanner.</span>
          </li>
          <li>
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />
            <span>Avalanche contract scanner.</span>
          </li>
          <li>
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />
            <span>ADA contract scanner.</span>
          </li>
          <li>
            <img
              src="https://jokercoin.live/_gfx/li_check_off.png"
              alt=""
              class="right-img"
            />
            <span>Token launchpad.</span>
          </li>
        </ul>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    // BBadge,
  },
};
</script>
<style>
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap);

.section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  -webkit-box-shadow: 0 22px 25px #97aedc;
  box-shadow: 0 22px 25px #97aedc;
  border-radius: 5px;
}
.section + .section {
  margin-left: 35px;
}
.section_header {
  background-color: #f8fafe;
  padding: 19px 66px;
  /* margin: 10px; */
  border-radius: 3px;
}
.section_header.lite {
  background-image: linear-gradient(45deg, #c3d4ff, #b26ffe);
}
.section_header.pro {
  background-image: linear-gradient(45deg, #ffb0c6, #ffcc6d);
}
.section_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.section_content p {
  width: 220px;
  font-size: 18px;
  margin-bottom: 60px;
  text-align: center;
}
.section_content span {
  font-size: 56px;
}
.section_content h5 {
  font-size: 16px;
  margin-bottom: 30px;
}
.section_content h5 {
  font-weight: 400;
  text-transform: uppercase;
}
.section_content-btn {
  width: 90%;
  display: block;
  text-decoration: none;
  padding: 15px 50px;
  text-align: center;
  border: 1px solid var(--bg);
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  margin-bottom: 40px;
}
.section_content-btn:hover {
  background-color: var(--bg);
  color: #fff;
  -webkit-transition-duration: 350ms;
  transition-duration: 350ms;
}
.section_footer {
  list-style: none;
  margin-bottom: 60px;
  padding-left: 30px;
}
.section_footer li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.section_footer li span {
  margin-left: 15px;
}
.section_footer li + li {
  margin-top: 25px;
}
#p09 li.on::before {
  background: url(https://jokercoin.live/_gfx/li_check_on.png) center top
    no-repeat;
  background-size: contain;
}
.card-class {
  position: relative;
  box-shadow: 0 0 10px 2px rgba(138, 138, 138, 0.2);
  padding: 1em;
}
.gold span.outline {
  filter: url(#stroke-text-svg-filter);
  background: linear-gradient(
    0deg,
    rgba(255, 225, 55, 1) 10%,
    rgba(255, 225, 55, 1) 40%,
    rgba(225, 160, 30, 1) 40%,
    rgba(255, 225, 55, 1) 55%,
    rgba(255, 225, 55, 1) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-origin: content-box;
}
.right-img {
  width: 7%;
  height: auto;
}
</style>