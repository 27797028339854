<template>
  <div class="d-flex justify-content-center pt-3 col-12 text-center">
    <b-row>
        <b-col align-self="center">
          <b-img
            src="@/assets/images/banners/matador-gif_2.gif"
            height="180"
            alt="RugFreeCoins"
          ></b-img>
        </b-col>
        <b-col align-self="center">
          <b-img
            src="@/assets/images/banners/regcoin.png"
            height="150"
            alt="RugFreeCoins"
          ></b-img>
        </b-col>
        <b-col align-self="center">
          <b-img
            src="@/assets/images/logo/rugfreecoins.png"
            height="210"
            alt="RugFreeCoins"
          ></b-img>
        </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow, BImg } from "bootstrap-vue";
export default {
  components: {
    BCol,
    BRow,
    BImg
  },
};
</script>