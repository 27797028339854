<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-center mb-3 col-12 text-center">
        <h2><b-badge> RUGSCAN TOKEN</b-badge></h2>
      </div>
      <b-row>
        <b-col md="3"></b-col>
        <b-col md="2"><h3 class="">Contract address :</h3></b-col>
        <b-col md="4">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="PackageIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Binance Smart Chain Contract"
              :value="bsc_contract_address"
              disabled
            />
          </b-input-group>
        </b-col>
        <b-col cols="2">
          <b-avatar variant="light-primary" rounded class="pointer">
            <feather-icon
              icon="CopyIcon"
              size="18"
              @click="doCopy(bsc_contract_address)"
            />
          </b-avatar>
        </b-col>
      </b-row>
      <!-- <flip-countdown :deadline="countDate"></flip-countdown> -->
      <div class="d-flex justify-content-center pt-3 col-12 text-center">
        <b-link href="https://t.me/rugscantoken" target="_blank">
          <b-button class="mr-1" variant="twitter" lg>
            <feather-icon icon="SendIcon" />
            Join us
          </b-button>
        </b-link>
      </div>
      <div>
        <logos />
      </div>
      <div class="d-flex justify-content-center mb-1 pt-1 col-12 text-start">
        <b-row class="text-start">
          <b-col>
            <!-- <taxTittle /> -->
            <roadMap />
          </b-col>
        </b-row>

        <br />
      </div>
      <div class="d-flex justify-content-center  col-12 text-center">
        <b-row class="text-start">
          <b-col class="pb-2">
              <buyTax v-if="!is_mobilesize" />
              <buyTaxMobile v-else />
          </b-col>
          <b-col>
              <sell-tax v-if="!is_mobilesize" />
              <sellTaxMobile v-else />
          </b-col>
        
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
// import FlipCountdown from "vue2-flip-countdown";
// import moment from "moment";
import buyTax from "./buyTax.vue";
import sellTax from "./sellTax.vue";
import buyTaxMobile from './buyTaxMobile.vue'
import sellTaxMobile from './sellTaxMobile.vue'
import logos from './logos.vue'
// import taxTittle from './taxTittle.vue'
import { mixinList } from "@/mixins/mixinList";
import roadMap from './roadMap.vue'

import {
  BCard,
  BBadge,
  BRow,
  BCol,
  BLink,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BAvatar,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  mixins: [mixinList],
  components: {
    sellTaxMobile,
    buyTaxMobile,
    buyTax,
    sellTax,
    logos,
    // taxTittle,
    roadMap,
    BRow,
    BCol,
    BCard,
    BBadge,
    BLink,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BAvatar,
    // FlipCountdown,
  },
  data() {
    return {
      bsc_contract_address: "0x6fed7d26a595a7ea48dec18b159eef8513b2cb95",
      // countDate: "",
    };
  },
  created() {
    // const vm = this;
    // var tz = moment.tz.guess();
    // let countDownDate = moment("2021-09-15 22:00:00", "YYYY-MM-DD HH:mm:ss")
    //   .tz(tz)
    //   .format("YYYY-MM-DD HH:mm:ss");
    // vm.countDate = countDownDate.toString();
  },
  methods: {
    doCopy(value) {
      navigator.clipboard.writeText(value).then(
        () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "BSC contract address copied",
              icon: "BellIcon",
            },
          });
        },
        (e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: e + "Can not copy!",
              icon: "BellIcon",
            },
          });
        }
      );
    },
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
.margin-class {
  margin-top: 300px;
}
</style>